import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import classNames from 'classnames';
import { Question } from '../../../../../common/FormWithSteps';
import CheckBox from '../../../../../common/FormWithSteps/Question/CheckBox';

const Template = ({
  showAdvisorType,
  handleQuestionChangeType,
  showAdvisorFee,
  requireConfirmationAdvisorBasis,
  requireConfirmationAdvisorFee,
  questions,
  handleQuestionChange,
  showAdvisorPoints,
  handleQuestionChangeConfirm,
  heading,
  content,
  putApplicationPanelInfo,
  role,
  handleQuestionChangeCompensation,
}) => {
  const {
    advisorType,
    advisorFee,
    confirmAdvisorFee,
    advisorPoints,
    advisorFirmBroker,
    advisorCompensation,
  } = questions;
  const { dirty, hasError } = advisorCompensation;
  const validate = !!(dirty && hasError);
  const advisorPointsFiltered = () => {
    if (advisorFirmBroker.value.maxBasisPoints?.length) {
      if (typeof advisorFirmBroker.value.maxBasisPoints === 'object') {
        return advisorFirmBroker.value.maxBasisPoints.map((option, index) => ({
          ...advisorPoints.options[0],
          label: option,
          value: `option_${index + 1}`,
          id: index,
          valueFormatted: option,
        }));
      }
      return advisorPoints.options.filter(
        (option) =>
          parseInt(option.label, 10) <=
          parseInt(advisorFirmBroker.value.maxBasisPoints || 75, 10),
      );
    }
    return advisorPoints.options;
  };

  const insertPoints = (text) =>
    text.replace('{points}', advisorPoints.valueFormatted);

  const confirmAdvisorPoints = {
    ...questions.confirmAdvisorPoints,
    options: questions.confirmAdvisorPoints.options.map((option) => ({
      ...option,
      label: insertPoints(option.label),
      info: insertPoints(option.info),
    })),
  };

  return (
    <div>
      <p className="fs-form-q fw-bold mb-md-5 text-center">
        We just need a few more specifics about your compensation.
      </p>
      <Question
        role={role}
        type={advisorCompensation.type}
        labelClass="fs-h4 fw-bold text-center"
        inputClass="srp-form-input form-control"
        radioWrapper="srp-form-toggle"
        question={advisorCompensation}
        handleQuestionChange={handleQuestionChangeCompensation}
      >
        {validate && (
          <p className="text-danger text-center">Question is required</p>
        )}
      </Question>
      <p className="text-center mb-0">
        <button
          type="button"
          className="srp-button__link pb-2"
          onClick={() =>
            putApplicationPanelInfo({
              hasVisibility: true,
              heading,
              additionalInformation: content,
            })
          }
        >
          Need clarification?
        </button>
      </p>
      {showAdvisorType && (
        <Question
          type={advisorType.type}
          question={advisorType}
          handleQuestionChange={handleQuestionChangeType}
          radioWrapper="srp-form-toggle"
          labelClass="fs-h4 fw-bold text-center"
        >
          {advisorType.disabled && (
            <p className="text-center text-muted my-3">
              Your broker-dealer does not allow changing this option.
            </p>
          )}
        </Question>
      )}
      {showAdvisorFee && (
        <div
          className={classNames('form-group text-center', {
            'has-error': advisorFee.hasError,
          })}
        >
          <label className="fs-h4 fw-bold" htmlFor={advisorFee.name}>
            {advisorFee.label}
          </label>
          {advisorFee.infoLabel && (
            <div
              className="fs-p text-center mb-2"
              dangerouslySetInnerHTML={{ __html: advisorFee.infoLabel }}
            />
          )}
          <NumericFormat
            style={{ width: 'auto', margin: 'auto' }}
            id={advisorFee.name}
            prefix={advisorFee.valuePrefix}
            allowNegative={false}
            className={classNames('fs-h3 srp-form-input form-control', {
              'is-invalid': advisorFee.hasError,
              'is-dirty': advisorFee.dirty,
            })}
            required
            name={advisorFee.name}
            thousandSeparator
            value={advisorFee.value}
            onValueChange={(values) => {
              handleQuestionChange(
                advisorFee.name,
                values.value,
                values.formattedValue,
              );
            }}
          />
          {advisorFee.hasError && (
            <p className="text-center text-danger my-3">
              Yearly fee must be less than $5,000
            </p>
          )}
        </div>
      )}

      {requireConfirmationAdvisorFee && (
        <CheckBox
          labelClass="fs-form-q fw-bold text-center"
          handleQuestionChange={handleQuestionChangeConfirm}
          {...confirmAdvisorFee}
        />
      )}

      {showAdvisorPoints && (
        <Question
          type={advisorPoints.type}
          question={{ ...advisorPoints, options: advisorPointsFiltered() }}
          handleQuestionChange={handleQuestionChange}
          radioWrapper="srp-form-toggle"
          labelClass="fs-h4 fw-bold text-center"
        />
      )}

      {requireConfirmationAdvisorBasis && (
        <CheckBox
          labelClass="fs-form-q fw-bold text-center"
          handleQuestionChange={handleQuestionChangeConfirm}
          {...confirmAdvisorPoints}
        />
      )}
    </div>
  );
};

Template.propTypes = {
  showAdvisorType: PropTypes.bool.isRequired,
  handleQuestionChangeType: PropTypes.func.isRequired,
  showAdvisorFee: PropTypes.bool.isRequired,
  requireConfirmationAdvisorBasis: PropTypes.bool.isRequired,
  requireConfirmationAdvisorFee: PropTypes.bool.isRequired,
  questions: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  showAdvisorPoints: PropTypes.bool.isRequired,
  handleQuestionChangeConfirm: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  setVisibility: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  handleQuestionChangeCompensation: PropTypes.func.isRequired,
};

export default Template;
