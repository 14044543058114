import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RadioQuestionCondensed = ({
  name,
  label,
  value,
  dirty,
  disabled,
  options,
  hasError,
  labelClass,
  handleQuestionChange,
  infoLabel,
  info,
  radioWrapper,
  children,
}) => (
  <fieldset
    className={classNames('form-group  text-center mb-5', {
      'has-error': hasError,
    })}
  >
    <legend
      className={labelClass}
      dangerouslySetInnerHTML={{ __html: label }}
    />
    {infoLabel && (
      <div
        className="fs-p text-center mb-2"
        dangerouslySetInnerHTML={{ __html: infoLabel }}
      />
    )}

    {info && (
      <div className="row justify-content-center">
        <p
          className="fs-p col-md-8 text-center mb-2"
          dangerouslySetInnerHTML={{ __html: info }}
        />
      </div>
    )}
    <div className={radioWrapper}>
      {options.map((option) => (
        <label
          key={`${name}_${option.id}`}
          className={classNames(`srp-form-checkbox`, {
            'has-error': hasError,
            active: value === option.value,
            dirty: dirty && !option.checked,
            disabled: disabled || false,
          })}
          htmlFor={`${name}_${option.id}`}
        >
          <input
            className="srp-form-checkbox__input"
            type="radio"
            name={name}
            id={`${name}_${option.id}`}
            value={option.value}
            checked={value === option.value}
            disabled={disabled || false}
            onChange={(input) => {
              handleQuestionChange(
                name,
                input.target.value,
                option.valueFormatted,
              );
            }}
          />
          <strong className="srp-form-checkbox__text">{option.label}</strong>
        </label>
      ))}
    </div>
    {children}
  </fieldset>
);

RadioQuestionCondensed.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.string,
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  hasError: PropTypes.bool,
  labelClass: PropTypes.string,
  handleQuestionChange: PropTypes.func,
  infoLabel: PropTypes.any,
  radioWrapper: PropTypes.string,
  info: PropTypes.any,
  children: PropTypes.any,
};

export default RadioQuestionCondensed;
